import React, { useEffect, useState } from 'react';
import banner from '@/assets/images/contact/bannerMobile.png';
import title from '@/assets/images/contact/title.png';
import icon1 from '@/assets/images/contact/icon1.png';
import icon2 from '@/assets/images/contact/icon2.png';
import icon3 from '@/assets/images/contact/icon3.png';
import styles from './index.module.less';
import mark from '@/assets/images/contact/mark.png';
import markActive from '@/assets/images/contact/mark_active.png';
import mark3 from '@/assets/images/contact/mark3.png';
import mark4 from '@/assets/images/contact/mark4.png';
import { cityList } from '@/pagesMobile/const';
import {Map} from 'react-bmapgl';

const ContactUs = () => {
    const [firstMarker, setFirstMarker] = useState(null);
    const [mapRef, setMapRef] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const markIcon = new window.BMapGL.Icon(mark, new window.BMapGL.Size(13, 21));
    const markActiveIcon = new window.BMapGL.Icon(markActive, new window.BMapGL.Size(19, 29));
    useEffect(()=> {
        cityList.map((val, idx) => {
            const point = new window.BMapGL.Point(val.longitude, val.latitude);
            const Marker = new window.BMapGL.Marker(point, {
                title: val.name,
                icon: idx == 0 ? markActiveIcon : markIcon
            })
            if(mapRef){
                mapRef.map.addOverlay(Marker);
            }
        })
    },[mapRef])

    function selectCity (index){
        setCurrentIndex(index);
        if(mapRef){
            const list =  mapRef.map.getOverlays();
            if(index != 0 ){
                list[0].setIcon(markIcon);
            }
            if (firstMarker!=null) {
                firstMarker.setIcon(markIcon);
            }
            setFirstMarker(list[index])
            list[index].setIcon(markActiveIcon);
            mapRef.map.setZoom(6)
        }
    }

    return(
    <div className={styles.contact}>
        <div className={styles.banner}>
            <img src={banner} alt="" />
            <div className={`${styles.banner_title} animateTopFade`}>
                <h4>联系分效、多城联动</h4>
            </div>
        </div>
        <div className={styles.panel1}>
            <div className={styles.content}>
                <h4 className={styles.title}>联系我们</h4>
                <img src={title} alt="" />
                <div className={styles.contactNumber}>
                    <div> <img src={icon1} alt="" /> <span>联系人：汪女士</span></div>
                    <div><img src={icon2} alt="" /> <span>电话：17705812754</span></div>
                    <div><img src={icon3} alt="" /> <span>邮箱：fx@fenxiaotech.com</span></div>
                </div>
                <div className={styles.map}>
                    <div className={styles.cityMap}>
                        <Map 
                            ref={ref => {
                                if (ref) {
                                    setMapRef(ref);
                                }
                            }}
                            center={{lng: 119.5313, lat: 29.8773}} 
                            zoom="6"
                            enableScrollWheelZoom
                        >
                        </Map>
                    </div>
                </div>
                <div className={styles.cityList}>
                    {
                        cityList.map((val, idx)=> (
                            <div onClick={() => selectCity(idx)}>
                                <img src={ currentIndex == idx ? mark3 : mark4} alt="" />
                                <h4 style={{ color: currentIndex == idx ? '#007AFF' : '' }}>{val.name}</h4>
                                <p style={{ color: currentIndex == idx ? '#007AFF' : '' }}>{val.address}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
)};

export default   ContactUs;
