import React, { useEffect, useState } from 'react';
import banner from '@/assets/images/contact/contact.jpg';
import title from '@/assets/images/contact/title.png';
import icon1 from '@/assets/images/contact/icon1.png';
import icon2 from '@/assets/images/contact/icon2.png';
import icon3 from '@/assets/images/contact/icon3.png';
import styles from './index.module.less';
import mark from '@/assets/images/contact/mark.png';
import markActive from '@/assets/images/contact/mark_active.png';
import { cityList } from '@/pages/const';
import {Map} from 'react-bmapgl';

const ContactUs = () => {
    const [firstMarker, setFirstMarker] = useState(null);
    const [mapRef, setMapRef] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const markIcon = new window.BMapGL.Icon(mark, new window.BMapGL.Size(26, 41));
    const markActiveIcon = new window.BMapGL.Icon(markActive, new window.BMapGL.Size(37, 51));
    useEffect(()=> {
        cityList.map((item, index) => {
            const point = new window.BMapGL.Point(item.longitude, item.latitude);
            const Marker = new window.BMapGL.Marker(point, {
                title: item.name,
                icon: index == 0 ? markActiveIcon : markIcon
            })
            if(mapRef){
                mapRef.map.addOverlay(Marker);
            }
        })
    },[mapRef])

    function selectCity (index){
        setCurrentIndex(index);
        if(mapRef){
            const list =  mapRef.map.getOverlays();
            if(index != 0 ){
                list[0].setIcon(markIcon);
            }
            if (firstMarker!=null) {
                firstMarker.setIcon(markIcon);
            }
            setFirstMarker(list[index])
            list[index].setIcon(markActiveIcon);
            mapRef.map.setZoom(6)
        }
    }

    return(
    <div className={styles.contact}>
        <div className={styles.banner}>
            <img src={banner} alt="" />
            <div className={`${styles.banner_title} animateTopFade`}>
                <h4>联系分效、多城联动</h4>
            </div>
        </div>
        <div className={styles.panel1}>
            <div className={styles.content}>
                <h4 className={styles.title}>联系我们</h4>
                <img src={title} alt="" />
                <div className={styles.contactNumber}>
                    <div> <img src={icon1} alt="" /> <span>联系人：汪女士</span></div>
                    <div><img src={icon2} alt="" /> <span>电话：17705812754</span></div>
                    <div><img src={icon3} alt="" /> <span>邮箱：fx@fenxiaotech.com</span></div>
                </div>
                <div className={styles.map}>
                    <Map 
                        ref={ref => {
                            if (ref) {
                                setMapRef(ref);
                            }
                        }}
                        center={{lng: 119.5313, lat: 29.8773}} 
                        zoom="6"
                        style={{height: 760 }}
                        enableScrollWheelZoom
                        className={styles.cityMap}
                    >
                        <div className={styles.cityList}>
                            {
                                cityList.map((item, index)=> (
                                    <div onClick={() => selectCity(index)}>
                                        <h4 style={{ color: currentIndex == index ? '#007AFF' : '' }}>{item.name}</h4>
                                        <p style={{ color: currentIndex == index ? '#007AFF' : '' }}>{item.address}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </Map>
                </div>
            </div>
        </div>
    </div>
)};

export default   ContactUs;
